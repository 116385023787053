<template>
  <div class="contentWidth" style="background-color:#ffffff; margin: 16px auto;padding-top: 20px;">
    <div>
      <Dropdown />
    </div>
    <div class="header-img1" style="">
      <img style="border-radius: 15px;" src="../../../assets/images/va.png" alt="" />
    </div>
    <div style="width:100%;margin-top: 20px;">
      <div style="font-size: 24px;">VA Saluran Agregasi</div>
    </div>

    <div style="width:100%;margin-top: 16px;">
      <div style="font-size: 16px; color: #8d8d8d;">Silakan pilih metode pembayaran sebelum membayar</div>
    </div>
    <div class="marginBottom" style="font-size: 20px; height: 180px; color: #ffffff;">.</div>
  </div>
</template>

<script>
import Dropdown from '@/views/payway/Idr/dropdown.vue'; // 你的组件路径可能会有所不同
export default {
  components: {
    Dropdown
  },
  data: function () {
    return {
      merchantName: 'BeingFi',  // 付款的商户默认
      avatar: require("../../../assets/images/b.png"), // 商户头像默认
      amount: 1,  // 支付金额默认
      resData: {},
      wxImg: require("../../../assets/images/b.png"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      payApiRes: {}, //支付api返回信息
      intervalOrderState: null, //订单状态定时器
      orderTime: "--:--:--" //剩余时间
    }
  }
}
</script>
<style lang="css" scoped>@import './../pay.css';</style>